<template>
  <Popup ref="popupRef" width="610px" title="新建商户签约信息" confirm-text="确认" :confirmLoading="confirmLoading" @confirm="confirm" @close="initForm">
    <el-form label-position="top" v-loading="formLoading">
      <el-form-item label="注册账号" required>
        <el-input placeholder="请输入注册账号" v-model="form.mobile" @blur="getContractInfo"></el-input>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input placeholder="请输入联系人姓名" v-model="form.contactName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input placeholder="请输入联系方式" v-model="form.contactInfo"></el-input>
      </el-form-item>
      <el-form-item label="客户来源">
        <el-input placeholder="请输入客户来源" v-model="form.source"></el-input>
      </el-form-item>
      <el-form-item label="招聘类目">
        <el-input placeholder="请输入招聘类目" v-model="form.category"></el-input>
      </el-form-item>
      <el-form-item label="我方签约主体">
        <el-input placeholder="请输入我方签约主体" v-model="form.contractEntity"></el-input>
      </el-form-item>
      <el-form-item label="签约方式" required>
        <el-radio-group v-model="form.contractType">
          <el-radio :label='Number(type)' v-for="([type, value],index) in Object.entries(contractTypes)" :key="index">{{value}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="签约状态" required>
        <el-radio-group v-model="form.status">
          <el-radio :label='item.type' v-for="(item,index) in statusTypes" :key="index">{{item.text}}</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="合同凭证">
        <UploadFile :showFileList="true" :fileList="form.contractEvidence" :multiple="true" 
        @success="handleUploadContract"
        @remove="handleRemoveContract"></UploadFile>
      </el-form-item>
      <el-form-item label="备注">
        <el-input placeholder="请输入备注" v-model="form.memo"></el-input>
      </el-form-item>
      <el-form-item label="关联主体(mid)">
        <el-input placeholder="请输入关联主体" v-model="form.relateEntity"></el-input>
      </el-form-item>
      <el-form-item label="关联凭证">
        <UploadFile :showFileList="true" :fileList="form.relateEvidence" :multiple="true" 
        @success="handleUploadRelate"
        @remove="handleRemoveRelate"></UploadFile>
      </el-form-item>
      <el-form-item label="签约时间" required>
        <el-date-picker
          v-model="form.contractTime"
          type="datetime"
          placeholder="选择签约时间"
          value-format="yyyy-MM-dd HH:ss:mm">
        </el-date-picker>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import UploadFile from '@/components/UploadFile'
import { contractManageApi } from '@/api/admin.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
export default {
  components: {
    Popup,
    UploadFile
  },
  data() {
    return {
      form: {},
      contractTypes: {
        1: '电子合同',
        2: '纸质合同',
      },
      statusTypes: [
        {text: '未合作', type:0},
        {text: '未签约', type:1},
        {text: '已签约', type:2},
      ],
      formLoading: false,
      isEdit: false,
      confirmLoading: false
    }
  },
  created() {
    this.initForm()
  },
  methods: {
    open(option={}) {
      if(option.isEdit) {
        this.isEdit = true
        this.getContractInfo()
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
      this.initForm()
    },
    initForm(resetMobile = true) {
      this.form = {
        category: '',
        contactInfo:'',
        contactName: '',
        contractEvidence: [],
        memo: '',
        mobile: resetMobile ? '':this.form.mobile,
        relateEntity: '',
        relateEvidence: [],
        contractEntity: '',
        contractType: 1,
        source: '',
        status: 0,
        contractTime: ''
      }
    },
    async confirm() {
      if(!this.form.mobile.trim()) return this.$tips({message: '请输入注册账号', type:'warning'})
      if(!this.form.contractTime) return this.$tips({message: '请选择签约时间', type:'warning'})
      const formData = {
        act: 1,
        contractInfoList: {
          category: this.form.category,
          contactInfo: this.form.contactInfo,
          contactName: this.form.contactName,
          memo: this.form.memo,
          mobile: this.form.mobile,
          relateEntity: this.form.relateEntity,
          contractEntity: this.form.contractEntity,
          contractType: this.form.contractType,
          source: this.form.source,
          status: 2,
          contractTime: this.form.contractTime
        }
      }
      try {
        const evdRes = await this.handleUploadFile()
        Object.assign(formData.contractInfoList, {
          contractEvidence: evdRes.contractEvidence,
          relateEvidence: evdRes.relateEvidence,
        })
      } catch (error) {
        this.$tips({message: '上传文件失败', type:'error'})
        return
      }
      console.log('formdata', formData)
      this.confirmLoading = true
      contractManageApi(formData).then(res => {
        console.log('新建商户签约', res)
        this.confirmLoading = false
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.confirmLoading = false
        this.$tips({message: err, type:'error'})
      })
    },
    handleUploadFile() {
      return new Promise(async (resolve, reject) => {
        let count = this.form.contractEvidence.length + this.form.relateEvidence.length
        const contractEvidence = []
        const relateEvidence = []
        const { uploadImageAsync } = await uploadImageUtil()
        if(!this.form.contractEvidence.length && !this.form.relateEvidence.length) {
          resolve({contractEvidence, relateEvidence})
        }
        if(this.form.contractEvidence.length > 0) {
          for (const evItem of this.form.contractEvidence) {
            if(evItem.file) {
              uploadImageAsync(evItem.file).then(res => {
                contractEvidence.push({
                  docName: evItem.name,
                  docAddress: res.ossUrl
                })
                count--
                if(count == 0) {
                  resolve({contractEvidence, relateEvidence})
                }
              }).catch(err => {
                reject(err)
              })
            } else {
              contractEvidence.push({
                docName: evItem.name,
                docAddress: evItem.ossUrl
              })
              count--
              if(count == 0) {
                resolve({contractEvidence, relateEvidence})
              }
            }
          }
        }
        if(this.form.relateEvidence.length > 0) {
          for (const evItem of this.form.relateEvidence) {
            if(evItem.file) {
              uploadImageAsync(evItem.file).then(res => {
                relateEvidence.push({
                  docName: evItem.name,
                  docAddress: res.ossUrl
                })
                count--
                if(count == 0) {
                  resolve({contractEvidence, relateEvidence})
                }
              }).catch(err => {
                reject(err)
              })
            } else {
              relateEvidence.push({
                docName: evItem.name,
                docAddress: evItem.ossUrl
              })
              count--
              if(count == 0) {
                resolve({contractEvidence, relateEvidence})
              }
            }
          }
        }
      })
    },
    handleUploadContract(e) {
      if(e && e.length >= 0) {
        e.forEach(file => {
          this.form.contractEvidence.push({
            name: file.name,
            file
          })
        })
      }
    },
    handleRemoveContract(e) {
      if(e && e.index >= 0) {
        this.form.contractEvidence.splice(e.index, 1)
      }
    },
    handleUploadRelate(e) {
      if(e && e.length >= 0) {
        e.forEach(file => {
          this.form.relateEvidence.push({
            name: file.name,
            file
          })
        })
      }
    },
    handleRemoveRelate(e) {
      if(e && e.index >= 0) {
        this.form.relateEvidence.splice(e.index, 1)
      }
    },
    getContractInfo() {
      if(!this.form.mobile.trim()) return
      this.formLoading = true
      contractManageApi({
        act: 0, // 查询
        regMobile: this.form.mobile.trim()
      }).then(res => {
        console.log('查询已填写签约信息', res)
        this.formLoading = false
        const contractInfo = res.data?.contractInfoList
        if(contractInfo) {
          this.form.category = contractInfo.category
          this.form.source = contractInfo.source
          this.form.contactName = contractInfo.contactName
          this.form.contactInfo = contractInfo.contactInfo
          this.form.status = contractInfo.status || 0
          this.form.memo = contractInfo.memo || ''

          // this.form.mobile = contractInfo.mobile
          this.form.relateEntity = contractInfo.relateEntity
          this.form.contractEntity = contractInfo.contractEntity
          this.form.contractType = contractInfo.contractType
          this.form.contractTime = contractInfo.contractTime
          if(contractInfo.contractEvidence?.length > 0) {
            contractInfo.contractEvidence.forEach(item => {
              this.form.contractEvidence.push({
                name: item.docName,
                ossUrl: item.docAddress
              })
            })
          }
          if(contractInfo.relateEvidence?.length > 0) {
            contractInfo.relateEvidence.forEach(item => {
              this.form.relateEvidence.push({
                name: item.docName,
                ossUrl: item.docAddress
              })
            })
          }
        } else {
          this.initForm(false)
        }
      }).catch(err => {
        this.formLoading = false
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>